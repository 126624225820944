export const Font = 'Source Sans Pro';
export const AltFont = 'Playfair Display';

export const TextColour = '#ffffff';
export const TitleColour = '#ffffff';

export const MainColour = '#071D21';
export const AltColour = '#10424B';
export const DarkColour = '#030B0C';

export const h1Size = '40px';
export const h2Size = '36px';
export const h3Size = '30px';
export const h4Size = '28px';
export const h5Size = '24px';
export const h6Size = '20px';

export const TextSize = '18px';
export const LineHeight = '1.8';

export const ParagraphSpacing = '30px';
export const TitleSpacing = '45px';

export const PDReg = '400';
export const PDBold = '700';
export const PDBlk = '900';

export const XLight = '200';
export const Light = '300';
export const Regular = '400';
export const Semi = '600';
export const Bold = '700';
export const Blk = '900';

export const Twitter = '#00aced';
export const Facebook = '#3b5998';
export const GooglePlus = '#dd4b39';
export const Pinterest = '#cb2027';
export const LinkedIn = '#007bb6';
export const YouTube = '#bb0000';
export const Tumblr = '#32506d';
export const Instagram = '#bc2a8d';
export const Soundcloud = '#ff3a00';