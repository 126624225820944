import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { darken, position, rem, rgba, transitions } from 'polished'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import { Col } from '../Container'
import MobileMenu from './lightbox'

import { MainColour, Regular, AltFont, PDBold, Font, Semi, AltColour } from '../../utils/variables'

import Loader from '../../images/loading-banner.webp'
import DropdownArrow from '../../images/icon-dropdown.svg'

export class MenuItems extends Component {

  state = {
    activeMenu: false,
    menuKey: null
  }

  showDropdown(index) {
    this.setState({
      activeMenu: true,
      menuKey: index
    })
  }

  hideDropdown() {
    this.setState({
      activeMenu: false,
      menuKey: null
    })
  }

  enableOverflow = () => {
    document.documentElement.classList.remove('modalOpen')
  }

  render() {
    const data = this.props.menu.edges[0].node.items
    const { activeMenu, menuKey } = this.state
    const mobile = this.props.mobile
    return (
      <>
        {data.map((item, index) =>
          <MenuItem
            key={index}
            onMouseEnter={() => this.showDropdown(index)}
            onTouchStart={() => activeMenu !== true && this.showDropdown(index)}
            onMouseLeave={() => this.hideDropdown(index)}
            className={((index === menuKey && activeMenu === true) ? 'active' : '') + item.classes}
            dropdown={item.wordpress_children && true}
            mobile={mobile}
          >
            {item.url === "#" ? 
              <a 
                href={item.url === "#" && undefined}
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
            :
              <AniLink
                to={item.url}
                target={item.target}
                cover
                bg={LinkAnimation}
                direction="right"
                duration={2}
                dangerouslySetInnerHTML={{ __html: item.title }}
                onClick={() => this.enableOverflow()}
              />
            }            
            {item.wordpress_children &&
              <Dropdown mobile={mobile} visible={(index === menuKey && activeMenu === true) ? 1 : 0}>
                {item.wordpress_children && item.wordpress_children.map((child, index) =>
                  <li key={index} className={child.classes}>
                    <AniLink
                      to={child.url}
                      target={child.target}
                      cover
                      bg={LinkAnimation}
                      direction="right"
                      duration={2}
                      dangerouslySetInnerHTML={{ __html: child.title }}
                      onClick={() => this.enableOverflow()}
                    />
                  </li>
                )}
              </Dropdown>
            }
          </MenuItem>
        )}
      </>
    )
  }
}

const Menu = (props) => (
  <MenuCol lg={{ width: "75%" }} xs={{ width: "20%" }}>
    <MenuOuter>
      <MenuItems menu={props.menu} />
    </MenuOuter>
    <MobileMenu menu={props.menu} contact={props.contact} />
  </MenuCol>
)

export default Menu

export const LinkAnimation = (
  `url(${Loader})
  center / cover
  no-repeat
  fixed
  padding-box
  content-box` +
  darken(0.1, MainColour)
)

const MenuCol = styled(Col)`
  text-align: right;
  position: relative;
` 

const MenuOuter = styled.ul`  
  vertical-align: top;
  width: auto;  
  margin: 0;
  display: none;
  @media (min-width: 1200px) {
    display: inline-block;
  }
`

const MenuItem = styled.li`
  position: relative; 
  a {
    color: #fff;
    line-height: 1;
    font-family: ${AltFont}; 
    font-weight: ${PDBold};
    font-size: ${rem('30px')};
    cursor: pointer;
  }
  a:hover, a:active, a:focus {
    color: rgba(255,255,255,0.8);
  }
  ${props => props.dropdown === true && css`
    background-image: url(${DropdownArrow});
    background-position: right 0px top ${props => props.mobile ? '12px' : '50%'};
    background-repeat: no-repeat;
    background-size: 6px;
    padding-right: ${rem('20px')};
  `}
  ${props => !props.mobile && css`
    float: left;
    margin: 0 0 0 ${rem('50px')};
    padding: ${rem('15px')} 0;
    a {
      font-size: ${rem('24px')};
    }
    &:first-child {
      margin: 0;
    }
    &:after {
      content: '';
      width: 0%;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 3px;
      border-radius: 1px;
      background-color: #fff;
      transition: all 0.3s ease;
      opacity: 0;
    }
    &.active {
      &:after {
        width: 100%;
        opacity: 1;
      }
    } 
    ${props => props.dropdown === true && css`
      padding-right: ${rem('20px')};
    `}
  `}     
`

const Dropdown = styled.ul`
  display: ${props => props.visible ? 'block' : 'none'};
  ${transitions('all 0.3s ease-in')};
  a {
    font-family: ${Font};
    font-weight: ${Semi};
    font-size: ${rem('18px')};
  }
  ${props => props.mobile ? css`
    margin: ${rem('15px')} 0 0 ${rem('5px')};
    overflow: hidden;
    animation: mobileDropdown 0.5s;
    li {
      margin: 0 0 ${rem('10px')} 0;
      &:last-child {
        margin: 0;
      }
    }
    /* a {
      font-weight: ${Regular};
      font-size: ${rem('18px')};      
    } */
  `
  : css`
    ${position('absolute', '63px', '', '', '0')};
    background-color: ${darken(0.1, AltColour)};
    margin: 0;
    width: 300px;
    text-align: left;
    animation: dropdown 0.5s;
    z-index: 100;
    li {
      padding: ${rem('10px')} ${rem('15px')};
      margin: 0;
      width: 100%;
      background-color: ${rgba('#000', 0)};      
      &:hover {
        background-color: ${rgba('#000', 0.1)};
      }
    }
  `}  
`