import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rem, position } from 'polished'
import { StaticQuery, graphql } from 'gatsby'

import { Container, Row } from '../Container'
import Logo from './logo'
import Menu from '../Menu'

const Header = ({ siteTitle }) => (
  <HeaderContainer>
    <Container>
      <Row align="center" justify="space-between">
        <Logo alt={siteTitle} />
        <StaticQuery
          query={graphql`
            query MenuQuery {
              allWordpressWpApiMenusMenusItems(
                filter: {
                  wordpress_id: {
                    eq: 2
                  }
                }
              ) {
                edges {
                  node {
                    slug
                    items {
                      wordpress_id
                      title
                      target
                      classes
                      url
                      wordpress_children {
                        wordpress_id
                        title
                        target
                        classes
                        url
                      }
                    }
                  }
                }
              }
              wordpressAcfOptions {
                options {
                  general {
                    phone_number
                    email_address
                  }
                }
              }
            }
          `}
          render={data => (
            <Menu menu={data.allWordpressWpApiMenusMenusItems} contact={data.wordpressAcfOptions.options.general} />              
          )} 
        />
      </Row>
    </Container>
  </HeaderContainer>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

export default Header

const HeaderContainer = styled.header`
  ${position('absolute','0','0','','0')};
  z-index: 100;
  padding: ${rem('30px')} 0;
  @media (min-width:1200px) {
    padding: ${rem('45px')} 0;
  }  
`