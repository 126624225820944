import React from 'react'
import styled, { css } from 'styled-components'
import { rem } from 'polished'

import { TitleColour, TitleSpacing, Bold, h1Size, h2Size, h3Size, h4Size, h5Size, h6Size } from '../../utils/variables'

export const H1 = (props) => (
  <header>
    <TitleH1
      size={props.size}
      line={props.line}
      margin={props.margin}
      dangerouslySetInnerHTML={props.dangerouslySetInnerHTML}
      className={props.className}
    />
  </header>  
)

export default H1

export const H2 = (props) => (
  <header>
    <TitleH2
      size={props.size}
      line={props.line}
      margin={props.margin}
      dangerouslySetInnerHTML={props.dangerouslySetInnerHTML}
      className={props.className}
    />
  </header>  
)

export const H3 = (props) => (
  <header>
    <TitleH3
      size={props.size}
      line={props.line}
      margin={props.margin}
      dangerouslySetInnerHTML={props.dangerouslySetInnerHTML}
      className={props.className}
    />
  </header>  
)

export const H4 = (props) => (
  <header>
    <TitleH4
      size={props.size}
      line={props.line}
      margin={props.margin}
      dangerouslySetInnerHTML={props.dangerouslySetInnerHTML}
      className={props.className}
    />
  </header>  
)

export const H5 = (props) => (
  <header>
    <TitleH5
      size={props.size}
      line={props.line}
      margin={props.margin}
      dangerouslySetInnerHTML={props.dangerouslySetInnerHTML}
      className={props.className}
    />
  </header>  
)

export const H6 = (props) => (
  <header>
    <TitleH6
      size={props.size}
      line={props.line}
      margin={props.margin}
      dangerouslySetInnerHTML={props.dangerouslySetInnerHTML}
      className={props.className}
    />
  </header>  
)

export const TitleShared = css`  
  color: ${TitleColour};
  margin-bottom: ${props => props.margin ? rem(props.margin) : rem(TitleSpacing)};
  font-weight: ${Bold};  
`

const TitleH1 = styled.h1`
  ${TitleShared}
  font-size: ${props => props.size ? rem(props.size) : rem(h1Size)};
`

const TitleH2 = styled.h2`
  ${TitleShared}
  font-size: ${props => props.size ? rem(props.size) : rem(h2Size)};
`

const TitleH3 = styled.h3`
  ${TitleShared}
  font-size: ${props => props.size ? rem(props.size) : rem(h3Size)};
`

const TitleH4 = styled.h4`
  ${TitleShared}
  font-size: ${props => props.size ? rem(props.size) : rem(h4Size)};
`

const TitleH5 = styled.h5`
  ${TitleShared}
  font-size: ${props => props.size ? rem(props.size) : rem(h5Size)};
`

const TitleH6 = styled.h6`
  ${TitleShared}
  font-size: ${props => props.size ? rem(props.size) : rem(h6Size)};
`