import React, { Component, Fragment } from 'react'
import styled, { css } from 'styled-components'
import { rem, rgba } from 'polished'

import { Container, Row, Col } from '../Container'
import { MenuItems } from '.'
import Hamburger from './hamburger'
import Copyright from '../Footer/copyright'

import { MainColour, Bold, AltFont, Semi } from '../../utils/variables'

class LightboxMenu extends Component {
  state = {
    showLightbox: false
  }

  handleClick = (e) => {
    e.preventDefault()
    this.setState({ showLightbox: !this.state.showLightbox })
    this.state.showLightbox ? document.documentElement.classList.remove('modalOpen') : document.documentElement.classList.add('modalOpen')
  }

  enableOverflow = (e) => {
    document.documentElement.classList.remove('modalOpen')
  }

  handleKeyUp = e => {
    e.preventDefault()
    const { keyCode } = e
    if (this.state.showLightbox) {
      if (keyCode === 27) {
        // Escape key
        this.setState({ showLightbox: false })
        document.documentElement.classList.remove('modalOpen')
      }
    }
  }

  render() {
    const data = this.props.menu
    const sidebar = this.props.sidebar
    const contact = this.props.contact
    const { showLightbox } = this.state
    return (
      <Fragment>
        <Button href="#" className={this.state.showLightbox ? 'active' : null} onClick={e => this.handleClick(e)} sidebar={sidebar}>
          <Hamburger active={this.state.showLightbox} />
        </Button>
        {showLightbox &&
          <LightboxModal className={this.state.showLightbox ? 'active' : null} visible={showLightbox} onKeyUp={e => this.handleKeyDown(e)}>
            <Container>
              <nav>
                <MenuOuter>
                  <MenuItems menu={data} mobile />
                </MenuOuter>
              </nav>              
            </Container>
            <LightboxFooter>
              <Container>
                <Row align="center" justify="space-between">
                  <Col md={{ width: 'auto' }}>
                    <Copyright />
                  </Col>
                  <Col md={{ width: 'auto' }}>
                  <ContactDetails>
                    <li>Tel: <a href={"tel:" + contact.phone_number}>{contact.phone_number}</a></li>
                    <li>Email: <a href={"mailto:" + contact.email_address}>{contact.email_address}</a></li>
                  </ContactDetails>
                  </Col>
                </Row>      
              </Container>
            </LightboxFooter>
          </LightboxModal>
        }        
      </Fragment>
    )
  }
}

export default LightboxMenu

const LightboxModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* display: flex;
  justify-content: center;
  align-items: start; */
  background: ${rgba(MainColour, 1)};
  opacity: ${props => (props.visible ? '1' : '0')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  z-index: 100;
  text-align: left;
  transition: all 0.3s ease;
  &.active {
    ul {
      transform: translate(0,0);
      opacity: 1;
      li {
        svg {
          transform: translate(0,0);
        }
      }
    }
  }
`

const LightboxFooter = styled.div`
  position: absolute;
  bottom: 0; 
  left: 0;
  width: 100%;
  padding: ${rem('60px')} 0;
  display: none;
  overflow: auto;
  @media(min-width: 768px) {
    display: inline-block;    
  }
  @media (min-width: 1200px) {
    overflow: hidden;
  }
`

const ContactDetails = styled.ul`
  font-family: ${AltFont};
  letter-spacing: 0;
  margin: ${rem('15px')} 0 0 0;
  li {
    float: left;
    margin-right: ${rem('30px')};
    &:last-child {
      margin-right: 0;
    }
    a {
      border-bottom: 1px dotted ${rgba('#fff', 0.2)};
      padding: 0 0 ${rem('7px')};
      transition: all 0.3s ease;
      &:hover, &:active, &:focus {
        color: #fff;
        border-bottom: 1px dotted ${rgba('#fff', 0.6)};
      }
    }
  }
  @media (min-width: 992px) {
    margin: 0;
    li {
      margin-right: ${rem('45px')};
    }
  }
`

const Button = styled.a`
  z-index: 200;
  display: inline-block;
  vertical-align: top;
  line-height: 0;
  position: absolute;
  
  svg {
    overflow: inherit;
    path {
      transition: all 0.3s ease;
    }
  }
  &.active {
    .middle {
      transform: translate(30px,0);
      opacity: 0;      
    }
    .top {
      transform: rotate(45deg);
    }
    .bottom {
      transform: rotate(-45deg) translate(-23px,-6px);
    }
  }
  ${props => props.sidebar ? css`
    top: 0;
    left: 50%;
    transform: translate(-50%,0);
  ` : css`
    top: 50%;  
    transform: translate(0,-50%);
    right: 15px;
    @media (min-width: 1200px) {
      display: none;
    }
  `}  
`

const MenuOuter = styled.ul`
  display: inline-block;
  width: 100%;
  padding: ${rem('90px')} ${rem('20px')} 0 0;
  transform: translate(30px,0);
  opacity: 0; 
  transition: all 0.3s ease;
  li {
    margin: 0 0 ${rem('30px')} 0;
    svg {
      margin: 0 0 0 ${rem('20px')};
      transform: translate(45px,0);
      transition: all 0.5s ease;
    }
    a {
      font-size: ${rem('24px')};
      font-weight: ${Bold};
    }
    ul li {
      margin: 0;
      a {
        font-weight: ${Semi};
        font-size: ${rem('18px')};
      }
    }
  }
  a {    
    color: #fff;    
  }
  a:hover, a:active, a:focus {
    color: rgba(255,255,255,0.6);
  }
  @media (min-width: 768px) {
    padding: ${rem('120px')} ${rem('20px')} 0 0;
    a {
      font-size: ${rem('36px')};
    }    
  }
`