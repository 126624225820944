import { createGlobalStyle } from 'styled-components'
import { rem, rgba } from 'polished'

import { TitleShared } from '../components/Title'

import { h1Size, h2Size, h3Size, h4Size, h5Size, h6Size, TextSize, Regular, Bold, TextColour, ParagraphSpacing, MainColour, LineHeight, DarkColour } from '../utils/variables'

import ArrowDown from '../images/icon-angle-down.svg'

const GlobalStyle = createGlobalStyle`
  *, *:before, *:after {
    box-sizing: border-box;
  }

  html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, ul, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
  }

  html, body {
    overflow-x: hidden;
    letter-spacing: -0.03em;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    &::-webkit-scrollbar {
        display: none;
    }
  }

  html {
    scroll-behavior: smooth;
    &.modalOpen, &.modalOpen body {
      overflow: hidden;
    }
    &.modalOpen {
      footer {
        visibility: hidden;
      }
    }
  }

  body {
    background-color: ${DarkColour};
  }

  *:focus {
      outline: none;
  }

  img, svg {
    max-width: 100%;
    height: auto;
    &.alignright, &.alignleft { 
        max-width: 25%; 
    }
    &.alignright { 
        float: right; 
        margin-left: 30px; 
        margin-bottom: 24px; 
    }
    &.alignleft { 
        float: left; 
        margin-right: 30px; 
        margin-bottom: 24px; 
    }
    &.aligncenter { 
        margin: 15px auto; 
        text-align: center; 
        display: block; 
        max-width: 100%;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    ${TitleShared}
  }

  h1 {
    font-size: ${rem(h1Size)};
  }

  h2 {
    font-size: ${rem(h2Size)};
  }

  h3 {
    font-size: ${rem(h3Size)};
  }

  h4 {
    font-size: ${rem(h4Size)};
  }

  h5 {
    font-size: ${rem(h5Size)};
  }

  h6 {
    font-size: ${rem(h6Size)};
  }

  p, ul, ol, dl, table { 
    font-size: ${rem(TextSize)};
    font-weight: ${Regular};
    line-height: ${LineHeight};
    color: ${TextColour};
    margin-bottom: ${ParagraphSpacing};
    &:last-child {
      margin-bottom: 0;
    }
  }

  ol, ul {
    list-style: none;
  }

  a {
    outline: none;
    text-decoration: none;
    color: ${TextColour};
    transition: all 0.3s ease;
    &:hover, &:active, &:focus { 
      color: ${MainColour}; 
    }
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  caption, th, td {
    text-align: left;
    font-weight: ${Regular};
    vertical-align: middle;
  }

  q, blockquote {
    quotes: none;
  }

  q:before, q:after, blockquote:before, blockquote:after {
    content: "";
    content: none;
  }

  a img {
    border: none;
  }

  article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
    display: block;
  }

  label {
    font-size: ${rem('14px')};
    font-weight: ${Bold};
    margin: 0 0 ${rem('20px')};
    display: block;
  }

  input, textarea, select {
    width: 100%;
    padding: 14px 20px;
    border-radius:10px;    
    border: 1px solid ${rgba('#000000', 0.2)};
  }

  input{
    &[type="number"] {
      appearance: textfield;
    }
    &[type="checkbox"] {
      width: auto;
    }
  }

  textarea {
    min-height: 100%;
    &:invalid {
      box-shadow: none;
    }
  }

  select {
    appearance: none;
    cursor: pointer;
    background-image: url(${ArrowDown});
    background-position: right 24px top 50%;
    background-repeat: no-repeat;
    background-size: 8px;
  }  

  @keyframes dropdown {
    0% {
      opacity: 0;
      -webkit-transform: translateY(50%);
      transform: translateY(50%)
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0)
    }
  }

  @keyframes mobileDropdown {
    0% {
      max-height: 0;
      margin: 0;
    }
    100% {
      max-height: 1000px;
      margin: ${rem('15px')} 0 0 ${rem('5px')};
    }
  }

  .tilt {
    transform-style: preserve-3d;
  }

  @keyframes cogRotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .icon-cog {
    animation: cogRotate 3s infinite;
    transform-origin: 55% 38%;
  }

  @keyframes arrowBounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-12px);
    }
    60% {
      transform: translateY(-6px);
    }
  }

  .icon-arrow {
    animation: arrowBounce 3s infinite;
  }
  

  @keyframes pencilMove {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(-2deg);
    }
    100% {
      transform: rotate(1deg);
    }
  }

  .icon-pencil {
    animation: pencilMove 1.5s infinite;
  }

  @keyframes speechFocus1 {
    0% {
      transform: scale(1) translate(0,0);
    }
    60% {
      transform: scale(1.05) translate(-3px,-5px);
    }
    100% {
      transform: scale(1) translate(0,0)
    }
  }

  @keyframes speechFocus2 {
    0% {
      transform: scale(0.95) translate(3px,2px);
    }
    60% {
      transform: scale(1) translate(0,0);
    }
    100% {
      transform: scale(0.95) translate(3px,2px)
    }
  }

  .icon-speech {
    animation: speechFocus1 2s infinite;
  }

  .icon-speech2 {
    animation: speechFocus2 2s infinite;
  }

  @keyframes drawArrow {
    0% {
      stroke-dashoffset: 1001;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes bounceArrow {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-15px);
    }
    60% {
      transform: translateY(-7px);
    }
  }

  @keyframes hideFooter { 
    to { 
      visibility: visible;
      opacity: 1; 
    } 
  }

`

export default GlobalStyle