import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import { rem } from 'polished'

import SEO from '../components/SEO'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Style from './style'

const Layout = (props) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            title
            siteUrl
          }
        }
      }
    `}
    render={data => (
      <>     
        <Style />          
        <SEO 
          title={props.title + " | " + data.site.siteMetadata.title} 
          seo={props.seo} 
          url={data.site.siteMetadata.siteUrl}
          location={props.location}
        />    
        <Header siteTitle={data.site.siteMetadata.title} />
          <Content padding={props.padding} homepage={props.homepage}>
            {props.children}
          </Content>   
        {!props.nofooter && <Footer />}        
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const Content = styled.main`
  ${props => !props.homepage && css`
    padding: ${rem('350px')} 0 0 0;
    @media (min-width: 768px) {
      padding: ${rem('400px')} 0 0 0;
    }
    @media (min-width: 992px) {
      padding: ${rem('500px')} 0 0 0;
    }
    @media (min-width: 1200px) {
      padding: ${ props => props.padding ? props.padding : rem('90px') + ' 0' };
    }
  `}    
`
