import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { rem } from 'polished'

import { AltFont } from '../../utils/variables'

export default (props) => (
  <StaticQuery
    query={graphql`
      query copyrightQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data =>
      <CopyrightText
        dangerouslySetInnerHTML={{ __html: 'Copyright &copy; ' + new Date().getFullYear() + ' ' + data.site.siteMetadata.title + ' (11887108). All rights reserved.' }}
        className={props.className}
      />
    }
  />
)

const CopyrightText = styled.p`
  font-family: ${AltFont};
  letter-spacing: 0;
  font-size: ${rem('12px')};
  @media (min-width: 576px) {
    font-size: ${rem('14px')};
  }
  @media (min-width: 768px) {
    font-size: ${rem('18px')};
  }
  /* text-align: center;
  @media (min-width: 992px) {
    text-align: left;
  } */
`