import React from 'react'
import styled from 'styled-components'
import { rem, rgba } from 'polished'
import { StaticQuery, graphql } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import { Container, Row, Col } from '../Container'
import { LinkAnimation } from '../Menu'
import Copyright from './copyright'

import { DarkColour, AltFont } from '../../utils/variables'

export default () => (
  <StaticQuery 
    query={graphql`
      query subFooterQuery {
        allWordpressWpApiMenusMenusItems(
          filter: {
            wordpress_id: {
              eq: 5
            }
          }
        ) {
          edges {
            node {
              items {
                title
                url
              }
            }
          }
        }
      }
    `}
    render={ data =>
      <Footer>
        <Container>
          <Row align="center" justify="space-between">
            <Col lg={{ width: 'auto' }}>
              <Copyright />
            </Col>
            <Col lg={{ width: 'auto' }}>
              <FooterMenu>
                {data.allWordpressWpApiMenusMenusItems.edges[0].node.items.map((item, index) =>
                  <li key={index}>
                    <AniLink
                      to={item.url}
                      cover
                      bg={LinkAnimation}
                      direction="right"
                      duration={2}
                      dangerouslySetInnerHTML={{ __html: item.title }}
                    />
                    {data.allWordpressWpApiMenusMenusItems.edges[0].node.items.length - 1 !== index && <span dangerouslySetInnerHTML={{ __html: "|" }} />}
                  </li>
                )}
              </FooterMenu>
            </Col>
          </Row>
        </Container>
      </Footer>
    }
  />  
) 

const Footer = styled.footer`
  padding: ${rem('75px')} 0;
  background-color: ${DarkColour};
  position: relative;
  text-align: center;
  * {
    font-family: ${AltFont};
    color: #fff;
  }
  @media (min-width: 992px) {
    text-align: left;
  }
`

const FooterMenu = styled.ul`
  margin: ${rem('15px')} 0 0 0;
  display: inline-block;
  width: auto;
  li {
    float: left;
    span {
      margin: 0 ${rem('10px')};
    }
    a {
      border-bottom: 1px dotted ${rgba('#fff',0.2)};
      padding: 0 0 ${rem('7px')};
      transition: all 0.3s ease;
      &:hover, &:active, &:focus {
        color: #fff;
        border-bottom: 1px dotted ${rgba('#fff', 0.6)};
      }
    }
  }
  @media (min-width: 992px) {
    margin: 0;
  }
`