import React from 'react'
import styled, { css } from 'styled-components'

const Hamburger = (props) => (
  <Outer>
    <Inner active={props.active}>
      <span></span>
      Menu
    </Inner>
  </Outer>  
)

export default Hamburger

const Outer = styled.div`
  position: relative;
  width: 30px;
  height: 26px;
`

const Inner = styled.div`
  font-size: 0;
  color: transparent; 
  span, &:before, &:after {
    position: absolute;
    left: 50%;    
    /* background-color: #fff; */
    width: 6px;
    height: 6px;
    border-radius: 10px;
    transition: all 0.2s ease;   
    border: 1px solid #fff; 
  }
  &:before, &:after {
    content: '';
    display: block; 
    transform: translate(-50%,0);
  }
  span {
    top: 50%;
    transform: translate(-50%,-50%);  
  }
  &:before {
    top: 0;
  }
  &:after {
    bottom: 0;
  }
  ${props => props.active && css`
    /* span {
      opacity: 0;
      left: 50%;
    } */
    &:before, &:after {
      left: auto;
      transform: none;
    }
    &:before {
      right: 3px;
      /* top: 11px; */
    }
    &:after {
      left: 3px;
      /* transform: rotate(-45deg); */
      /* bottom: 11px; */
    }
  `}
`