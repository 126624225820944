import React, { Component } from 'react'
import Helmet from 'react-helmet'
import domToReact from 'html-react-parser'

class SEO extends Component {
  render() {
    const seo = this.props.seo
    const title = domToReact(this.props.title)
    const siteUrl = this.props.url
    const path = this.props.location
    return (
      <Helmet title={title}>
        <html lang="en" />
        <link rel="canonical" href={siteUrl + path} />        
        <script type="application/ld+json">
          {`
            "@context":"https://schema.org",
            "@type": "WebSite",
            "@id": "#website",
            "url": ${siteUrl + path},
            "name": ${title},
          `}
        </script>
        <meta property="og:url" content={siteUrl + path} />
        {seo && <meta property="og:locale" content="en_GB" />}
        {seo && <meta property="og:type" content="website" />}
        {seo && <meta property="og:title" content={seo.opengraph_title ? seo.opengraph_title : title} />}
        {seo && <meta property="og:site_name" content={title} />}
        {seo && <meta property="og:description" content={seo.opengraph_description ? seo.opengraph_description : seo.metadesc} />}
        {seo && <meta name="og:image" content={seo.opengraph_image && `${siteUrl + seo.opengraph_image.localFile.publicURL}`} />}
        {seo && <meta name="twitter:card" content="summary_large_image" />}
        {seo && <meta name="twitter:title" content={seo.twitter_title ? seo.twitter_title : title} />}
        {seo && <meta property="twitter:description" content={seo.twitter_description ? seo.twitter_description : seo.metadesc} />}
        {seo && <meta property="twitter:image" content={seo.twitter_image && `${siteUrl + seo.twitter_image.localFile.publicURL}`} />}
        {seo && <meta name="description" content={seo.metadesc && seo.metadesc} />}
        {seo && <meta name="image" content={seo.opengraph_image && `${siteUrl + seo.opengraph_image.localFile.publicURL}`} />}    
      </Helmet>
    )
  }
}

export default SEO