import React from 'react'
import styled, { css } from 'styled-components'
import { rem } from 'polished'

export const Container = ({ children }) => (
  <Outer>
    { children }
  </Outer>
)

export default Container

export const Row = (props) => (
  <Inner align={props.align} justify={props.justify} className={props.className}>
    {props.children}
  </Inner>
)

export const ContainerRow = (props) => (
  <Outer className={props.className}>
    <Inner align={props.align} justify={props.justify}>
      {props.children}
    </Inner>
  </Outer>
)

export const Col = (props) => (
  <Column
    xs={props.xs}
    sm={props.sm}
    md={props.md}
    lg={props.lg}
    xl={props.xl}
    xxl={props.xxl}
    w={props.w}
    className={props.className}
  >
    {props.children}
  </Column>
)

const Outer = styled.div`
  width: 100%;
  padding-right: ${rem('15px')};
  padding-left: ${rem('15px')};
  margin-right: auto;
  margin-left: auto;
  position: relative;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
  @media (min-width: 1400px) {
    max-width: 1340px;
  }
  @media (min-width: 1600px) {
    max-width: 1540px;
  }
`

const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: ${rem('-15px')};
  margin-left: ${rem('-15px')};
  align-items: ${ props => props.align};
  justify-content: ${ props => props.justify};
`

const Column = styled.div`
  padding-right: ${rem('15px')};
  padding-left: ${rem('15px')};
  flex: 0 0 ${props => props.xs ? props.xs.width : '100%'};
  max-width: ${props => props.xs ? props.xs.width : '100%'};
  order: ${props => props.xs && props.xs.order};
  ${props => props.sm && css`
    @media (min-width: 576px) {
      flex: 0 0 ${props => props.sm.width};
      max-width: ${props => props.sm.width};
      order: ${props => props.sm.order};
    }
  `}
  ${props => props.md && css`
    @media (min-width: 768px) {
      flex: 0 0 ${props => props.md.width};
      max-width: ${props => props.md.width};
      order: ${props => props.md.order};
    }
  `}
  ${props => props.lg && css`
    @media (min-width: 992px) {
      flex: 0 0 ${props => props.lg.width};
      max-width: ${props => props.lg.width};
      order: ${props => props.lg.order};
    }
  `}
  ${props => props.xl && css`
    @media (min-width: 1200px) {
      flex: 0 0 ${props => props.xl.width};
      max-width: ${props => props.xl.width};
      order: ${props => props.xl.order};
    }
  `}
  ${props => props.xxl && css`
    @media (min-width: 1400px) {
      flex: 0 0 ${props => props.xxl.width};
      max-width: ${props => props.xxl.width};
      order: ${props => props.xxl.order};
    }
  `}
  ${props => props.w && css`
    @media (min-width: 1600px) {
      flex: 0 0 ${props => props.w.width};
      max-width: ${props => props.w.width};
      order: ${props => props.w.order};
    }
  `}
`