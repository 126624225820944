import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import SVG from 'react-inlinesvg'
import Tilt from 'react-tilt'

import { Col } from '../Container'
import { LinkAnimation } from '../Menu'

const Logo = props => (
  <StaticQuery
    query={graphql`
      query {
        wordpressAcfOptions {
          options {
            general {
              logo {
                localFile {
                  extension
                  publicURL
                  # childImageSharp {
                  #   fluid(maxWidth: 240) {
                  #     ...GatsbyImageSharpFluid
                  #   }
                  # }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Col lg={{ width: '25%' }} xs={{ width: '80%' }}>
        <AniLink
          to="/"
          cover
          bg={LinkAnimation}
          direction="right"
          duration={2}
          aria-label={props.alt}
        >
          <LogoImg className="tilt" options={{ max: 15, scale: 1 }}>
            {' '}
            {data.wordpressAcfOptions.options.general.logo.localFile
              .extension === 'svg' ? (
              <SVG
                src={
                  data.wordpressAcfOptions.options.general.logo.localFile
                    .publicURL
                }
              />
            ) : (
              <Img
                fluid={
                  data.wordpressAcfOptions.options.general.logo.localFile
                    .childImageSharp.fluid
                }
                alt={props.alt}
              />
            )}
          </LogoImg>
        </AniLink>
      </Col>
    )}
  />
)

export default Logo

const LogoImg = styled(Tilt)`
  max-width: 180px;
  line-height: 0;
  * {
    line-height: 0;
  }
  @media (min-width: 768px) {
    max-width: 240px;
  }
`
