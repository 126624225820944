import React from 'react'
import PropTypes from 'prop-types'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import styled, { css } from 'styled-components'
import { rem, darken } from 'polished'

import { LinkAnimation } from '../Menu'

import { AltColour, Bold, DarkColour } from '../../utils/variables'

export const Button = (props) => (
  <StandardLink 
    href={props.href} 
    className={props.className}
    target={props.target}
    rel={props.rel}
    primary={props.primary ? 1 : 0}
    secondary={props.secondary ? 1 : 0}
  >
    {props.children}
  </StandardLink>
)

export default Button

export const ButtonLink = (props) => (
  <GatsbyLink
    to={props.to}
    className={props.className}
    activeClassName="active"
    primary={props.primary ? 1 : 0}
    secondary={props.secondary ? 1 : 0}
    cover
    bg={LinkAnimation}
    direction="right"
    duration={2}
  >
    {props.children}
  </GatsbyLink>
)

export const ButtonInput = (props) => (
  <InputButton
    type="submit"
    className={props.className}
    value={props.value}
    primary={props.primary ? 1 : 0}
    secondary={props.secondary ? 1 : 0}
  />
)

Button.propTypes = {
  primary: PropTypes.bool
};

const ButtonStyles = css`
  display: inline-block;
  transition: all 0.3s linear;
  position: relative;
  padding: ${rem('15px')} ${rem('45px')};
  color: #fff;
  border-radius: 100px;
  border-color: transparent;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  font-size: ${rem('18px')};
  font-weight: ${Bold};
  overflow: hidden;
  z-index: 1;
  @media (min-width: 1200px) {
    padding: ${rem('20px')} ${rem('75px')};
    font-size: ${rem('24px')};
  }
  ${props => props.primary && css`
    border: 4px solid #fff;
    background-color: transparent;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 100%;
      background-color: #fff;
      transition: all 0.2s ease;
      z-index: -1;
    }
    &:hover, &:active, &:focus {
      &:before {
        top: 0;
      }
      color: ${DarkColour}
    }
  `}
  ${props => props.secondary && css`
    background-color: ${AltColour};
    &:hover, &:active, &:focus {
      background-color: ${darken(0.1,AltColour)};
    }
  `}
`

const StandardLink = styled.a`
  ${ButtonStyles}
`

const GatsbyLink = styled(AniLink)`
  ${ButtonStyles}
`

const InputButton = styled.input`
  ${ButtonStyles}
`